import React from "react";
import PropTypes from "prop-types";

import EnvironmentBackupPane from "Containers/EnvironmentBackupPane";

class EnvironmentActionsBackup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <EnvironmentBackupPane
        push={this.props.router.push}
        {...this.props.params}
      />
    );
  }
}

EnvironmentActionsBackup.propTypes = {
  params: PropTypes.shape({
    organizationId: PropTypes.string.isRequired,
    projectId: PropTypes.string.isRequired,
    environmentId: PropTypes.string.isRequired
  }),
  router: PropTypes.shape({
    push: PropTypes.func.isRequired
  })
};

export default EnvironmentActionsBackup;
